import './Jumper.scss'
import './Anim.scss'

const Jumper = () => {
    return (
        <div className='jumper'>
            <div className='jumper__circle'>
                <div className='logo'>
                    <svg width="117" height="28" viewBox="0 0 117 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M20.9372 22.139C21.8294 21.3602 22.7181 20.1915 22.7181 20.1915L25.8334 23.7603C25.8334 23.7603 24.9447 24.929 24.0525 25.7079C23.2745 26.3869 21.8939 27.2228 21.8939 27.2228L18.7787 23.654C18.7787 23.654 20.1593 22.8181 20.9372 22.139Z" stroke="currentColor" />
                        <path d="M22.498 19.9137C24.1406 17.703 25.0656 14.9366 24.9449 11.9704C24.6648 5.08498 18.8559 -0.269656 11.9704 0.0105116C5.08498 0.290679 -0.269656 6.09956 0.0105116 12.985C0.290679 19.8705 6.09956 25.2251 12.985 24.9449C15.0101 24.8625 16.9027 24.3019 18.5586 23.3763L15.2565 19.5934C14.4884 19.8933 13.6583 20.073 12.7882 20.1084C8.57392 20.2798 5.01855 17.0025 4.84707 12.7882C4.67559 8.57392 7.95294 5.01855 12.1672 4.84707C16.3815 4.67559 19.9369 7.95294 20.1084 12.1672C20.1666 13.5974 19.8276 14.9517 19.1896 16.1236L22.498 19.9137Z" stroke="currentColor" />
                        <path d="M39.9397 0.208227C42.4244 0.208227 44.6725 0.823487 46.6839 2.05401C48.6953 3.26086 50.2098 4.9055 51.2273 6.98791L46.3289 8.7982C45.69 7.63867 44.8144 6.72761 43.7022 6.06503C42.59 5.40244 41.3359 5.07115 39.9397 5.07115C37.8336 5.07115 36.0943 5.79289 34.7218 7.23638C33.3493 8.67988 32.6631 10.4665 32.6631 12.5962C32.6631 14.7497 33.3493 16.5481 34.7218 17.9916C36.0943 19.4351 37.8336 20.1568 39.9397 20.1568C41.3359 20.1568 42.59 19.8255 43.7022 19.163C44.8144 18.5004 45.69 17.5893 46.3289 16.4298L51.2273 18.2401C50.2098 20.3225 48.6953 21.979 46.6839 23.2095C44.6725 24.4163 42.4244 25.0198 39.9397 25.0198C36.3428 25.0198 33.3493 23.8366 30.9593 21.4702C28.5692 19.0801 27.3742 16.134 27.3742 12.6317C27.3742 9.12949 28.5692 6.18334 30.9593 3.7933C33.3966 1.40325 36.3901 0.208227 39.9397 0.208227Z" stroke="currentColor" />
                        <path d="M54.0093 0.634177H59.0852V19.9439H70.8698V24.5938H54.0093V0.634177Z" stroke="currentColor" />
                        <path d="M77.2191 24.5938H71.9302L82.082 0.634177H86.235L96.3868 24.5938H91.0979L89.3586 20.3698H78.9584L77.2191 24.5938ZM87.4774 15.7909L84.1763 7.83981L80.8752 15.7909H87.4774Z" stroke="currentColor" />
                        <path d="M102.195 14.7615L93.1791 0.634177H98.965L104.68 10.0405L110.572 0.634177H116.358L107.235 14.6195V24.5938H102.195V14.7615Z" stroke="currentColor" />
                    </svg>
                </div>
            </div>
        </div>
    )
}

export default Jumper
import Icon from "../../components/UI/Icon/Icon";
import css from "./Terms.module.scss";

const Terms = () => {
    return (
        <div className={css.root}>
            <div className={css.container}>
                <header className={css.header}>
                    <Icon className="header__logo" id="logo" />
                </header>

                <div className={css.terms}>
                    <h4>Last updated December 02, 2023</h4>
                    <p>
                        'We are Get QClay Inc, doing business as QClay ("Company," "we," "us," "our"), a company
                        registered in California, United States at 15821 Ventura Boulevard, Suite 370, Encino, CA
                        91436.'
                    </p>
                    <p>
                        We operate the mobile application QClay (the "App"), as well as any other related products and
                        services that refer or link to these legal terms (the "Legal Terms") (collectively, the
                        "Services").
                    </p>
                    <p>
                        You can contact us by email at info@qclay.design, or Dubai Digital Park, Dubai Silicon Oasis,
                        Dubai, UAE.
                    </p>
                    <p>
                        These Legal Terms constitute a legally binding agreement made between you, whether personally or
                        on behalf of an entity ("you"), and Get QClay Inc, concerning your access to and use of the
                        Services. You agree that by accessing the Services, you have read, understood, and agreed to be
                        bound by all of these Legal Terms. IF YOU DO NOT AGREE WITH ALL OF THESE LEGAL TERMS, THEN YOU
                        ARE EXPRESSLY PROHIBITED FROM USING THE SERVICES AND YOU MUST DISCONTINUE USE IMMEDIATELY.
                    </p>
                    <p>
                        Supplemental terms and conditions or documents that may be posted on the Services from time to
                        time are hereby expressly incorporated herein by reference. We reserve the right, in our sole
                        discretion, to make changes or modifications to these Legal Terms from time to time. We will
                        alert you about any changes by updating the "Last updated" date of these Legal Terms, and you
                        waive any right to receive specific notice of each such change. It is your responsibility to
                        periodically review these Legal Terms to stay informed of updates. You will be subject to, and
                        will be deemed to have been made aware of and to have accepted, the changes in any revised Legal
                        Terms by your continued use of the Services after the date such revised Legal Terms are posted.
                    </p>
                    <p>
                        The Services are intended for users who are at least 13 years of age. All users who are minors
                        in the jurisdiction in which they reside (generally under the age of 18) must have the
                        permission of, and be directly supervised by, their parent or guardian to use the Services. If
                        you are a minor, you must have your parent or guardian read and agree to these Legal Terms prior
                        to you using the Services.
                    </p>

                    <div className={css.terms_sep} />

                    <p>
                        The information provided when using the Services is not intended for distribution to or use by
                        any person or entity in any jurisdiction or country where such distribution or use would be
                        contrary to law or regulation or which would subject us to any registration requirement within
                        such jurisdiction or country. Accordingly, those persons who choose to access the Services from
                        other locations do so on their own initiative and are solely responsible for compliance with
                        local laws, if and to the extent local laws are applicable.
                    </p>
                    <p>
                        The Services are not tailored to comply with industry-specific regulations (Health Insurance
                        Portability and Accountability Act (HIPAA), Federal Information Security Management Act (FISMA),
                        etc.), so if your interactions would be subjected to such laws, you may not use the Services.
                        You may not use the Services in a way that would violate the Gramm-Leach-Bliley Act (GLBA).
                    </p>

                    <div className={css.terms_sep} />

                    <p>
                        We are the owner or the licensee of all intellectual property rights in our Services, including
                        all source code, databases, functionality, software, website designs, audio, video, text,
                        photographs, and graphics in the Services (collectively, the "Content"), as well as the
                        trademarks, service marks, and logos contained therein (the "Marks").
                    </p>
                    <p>
                        Our Content and Marks are protected by copyright and trademark laws (and various other
                        intellectual property rights and unfair competition laws) and treaties in the United States and
                        around the world.
                    </p>
                    <p>
                        The Content and Marks are provided in or through the Services "AS IS" for your personal,
                        non-commercial use or internal business purpose only.
                    </p>
                    <p>
                        Subject to your compliance with these Legal Terms, including the "
                        <a
                            href="https://app.termly.io/embed/terms-of-use/54368b9e-0062-4a68-ac01-4fdb8fa2b7e8#prohibited"
                            target="_blank"
                            rel="noreferrer"
                        >
                            PROHIBITED ACTIVITIES
                        </a>
                        " section below, we grant you a non-exclusive, non-transferable, revocable license to:
                    </p>
                    <ul>
                        <li>access the Services; and</li>
                        <li>
                            download or print a copy of any portion of the Content to which you have properly gained
                            access.
                        </li>
                    </ul>
                    <p>solely for your personal, non-commercial use or internal business purpose.</p>
                    <p>
                        Except as set out in this section or elsewhere in our Legal Terms, no part of the Services and
                        no Content or Marks may be copied, reproduced,\naggregated, republished, uploaded, posted,
                        publicly displayed, encoded,\ntranslated, transmitted, distributed, sold, licensed, or otherwise
                        exploited\nfor any commercial purpose whatsoever, without our express prior written\npermission.
                    </p>
                    <p>
                        If you wish to make any use of the Services, Content, or Marks other than as set out in this
                        section or elsewhere in our Legal Terms, please address your request to: team@seen.video. If we
                        ever grant you the permission to post, reproduce, or publicly display any part of our Services
                        or Content, you must identify us as the owners or licensors of the Services, Content, or Marks
                        and ensure that any copyright or proprietary notice appears or is visible on posting,
                        reproducing, or displaying our Content.
                    </p>
                    <p>
                        We reserve all rights not expressly granted to you in and to the Services, Content, and Marks.
                    </p>
                    <p>
                        Any breach of these Intellectual Property Rights will constitute a material breach of our Legal
                        Terms and your right to use our Services will terminate immediately.
                    </p>
                    <div className={css.terms_sep} />
                    <p>
                        Please review this section and the "
                        <a
                            href="https://app.termly.io/embed/terms-of-use/54368b9e-0062-4a68-ac01-4fdb8fa2b7e8#prohibited"
                            target="_blank"
                            rel="noreferrer"
                        >
                            PROHIBITED ACTIVITIES
                        </a>
                        " section carefully prior to using our Services to understand the (a) rights you give us and (b)
                        obligations you have when you post or upload any content through the Services.
                    </p>
                    <p>
                        <strong>Submissions:</strong> By directly sending us any question, comment, suggestion, idea,
                        feedback, or other information about the Services ("Submissions"), you agree to assign to us all
                        intellectual property rights in such Submission. You agree that we shall own this Submission and
                        be entitled to its unrestricted use and dissemination for any lawful purpose, commercial or
                        otherwise, without acknowledgment or compensation to you.
                    </p>
                    <p>
                        <strong>Contributions:</strong> The Services may invite you to chat, contribute to, or
                        participate in blogs, message boards, online forums, and other functionality during which you
                        may create, submit, post, display, transmit, publish, distribute, or broadcast content and
                        materials to us or through the Services, including but not limited to text, writings, video,
                        audio, photographs, music, graphics, comments, reviews, rating suggestions, personal
                        information, or other material ("Contributions"). Any Submission that is publicly posted shall
                        also be treated as a Contribution.
                    </p>
                    <p>You understand that Contributions may be viewable by other users of the Services.</p>
                    <p>
                        <strong>
                            When you post Contributions, you grant us a license (including use of your name, trademarks,
                            and logos):
                        </strong>
                         By posting any Contributions, you grant us an unrestricted, unlimited, irrevocable, perpetual,
                        non-exclusive, transferable, royalty-free, fully-paid, worldwide right, and license to: use,
                        copy, reproduce, distribute, sell, resell, publish, broadcast, retitle, store, publicly perform,
                        publicly display, reformat, translate, excerpt (in whole or in part), and exploit your
                        Contributions (including, without limitation, your image, name, and voice) for any purpose,
                        commercial, advertising, or otherwise, to prepare derivative works of, or incorporate into other
                        works, your Contributions, and to sublicense the licenses granted in this section. Our use and
                        distribution may occur in any media formats and through any media channels.
                    </p>
                    <p>
                        This license includes our use of your name, company name, and franchise name, as applicable, and
                        any of the trademarks, service marks, trade names, logos, and personal and commercial images you
                        provide.
                    </p>
                    <p>
                        <strong>You are responsible for what you post or upload:</strong> By sending us Submissions
                        and/or posting Contributions through any part of the Services or making Contributions accessible
                        through the Services by linking your account through the Services to any of your social
                        networking accounts, you:
                    </p>
                    <ul>
                        <li>
                            confirm that you have read and agree with our "PROHIBITED ACTIVITIES" and will not post,
                            send, publish, upload, or transmit through the Services any Submission nor post any
                            Contribution that is illegal, harassing, hateful, harmful, defamatory, obscene, bullying,
                            abusive, discriminatory, threatening to any person or group, sexually explicit, false,
                            inaccurate, deceitful, or misleading;
                        </li>
                        <li>
                            to the extent permissible by applicable law, waive any and all moral rights to any such
                            Submission and/or Contribution;
                        </li>
                        <li>
                            warrant that any such Submission and/or Contributions are original to you or that you have
                            the necessary rights and licenses to submit such Submissions and/or Contributions and that
                            you have full authority to grant us the above-mentioned rights in relation to your
                            Submissions and/or Contributions; and
                        </li>
                        <li>
                            warrant and represent that your Submissions and/or Contributions do not constitute
                            confidential information.
                        </li>
                    </ul>
                    <p>
                        You are solely responsible for your Submissions and/or Contributions and you expressly agree to
                        reimburse us for any and all losses that we may suffer because of your breach of (a) this
                        section, (b) any third party’s intellectual property rights, or (c) applicable law.
                    </p>
                    <p>
                        <strong>We may remove or edit your Content:</strong> Although we have no obligation to monitor
                        any Contributions, we shall have the right to remove or edit any Contributions at any time
                        without notice if in our reasonable opinion we consider such Contributions harmful or in breach
                        of these Legal Terms. If we remove or edit any such Contributions, we may also suspend or
                        disable your account and report you to the authorities.
                    </p>
                    <h5>Copyright infringement</h5>
                    <p>
                        We respect the intellectual property rights of others. If you believe that any material
                        available on or through the Services infringes upon any copyright you own or control, please
                        immediately refer to the "
                        <a
                            target="_blank"
                            href="https://app.termly.io/embed/terms-of-use/54368b9e-0062-4a68-ac01-4fdb8fa2b7e8#copyrightyes"
                            rel="noreferrer"
                        >
                            COPYRIGHT INFRINGEMENTS
                        </a>
                        " section below.
                    </p>
                    <h5>USER REPRESENTATIONS</h5>
                    <p>
                        By using the Services, you represent and warrant that: (1) all registration information you
                        submit\nwill be true, accurate, current, and complete; (2) you will maintain the accuracy of
                        such information and promptly update such registration information as necessary; (3) you have
                        the legal capacity and you agree to comply with these Legal Terms; (4) you are not under the age
                        of 13; (5) you are not a\nminor in the jurisdiction in which you reside, or if a minor, you
                        have\nreceived parental permission to use the Services; (6) you will not access the Services
                        through automated or non-human means, whether through a bot, script or\notherwise; (7) you will
                        not use the Services for any illegal or unauthorized purpose; and (8) your use of the Services
                        will not violate any applicable law or regulation.
                    </p>
                    <p>
                        If you provide any information that is untrue, inaccurate, not current, or incomplete, we have
                        the right to suspend or terminate your account and refuse any and all current or future use of
                        the Services (or any portion thereof).
                    </p>
                    <h5>USER REGISTRATION</h5>
                    <p>
                        You may be required to register to use the Services. You agree to keep your password
                        confidential and will be responsible for all use of your account and password. We reserve the
                        right to remove, reclaim, or change a username you select if we determine, in our sole
                        discretion, that such username is inappropriate, obscene, or otherwise objectionable.
                    </p>
                </div>
            </div>
        </div>
    );
};

export default Terms;

export const screens = {
    MAIN: 'main',
    ABOUT: 'about',
    CREATE: 'create',
    WHATCREATE: 'whatcreate',
    DESIGNERS: 'designers',
    PORTFOLIO: 'portfolio',
    FOLLOWED: 'followed',
    STARTUPS: 'startups',
    REQUESTFORM: 'requestform'
}
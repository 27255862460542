import "./Portfolio.scss"
import "./Anim.scss"
// import SocialItem from "@/components/UI/SocialItem/SocialItem";
import { $t } from "@/i18n/i18n";
// import Spelling from '@/components/Spelling/Spelling'
import RandomSpelling from '@/components/RandomSpelling/RandomSpelling'
import { useEffect, useRef, useState } from "react";
import { Timeline } from './timeline'
import { itl } from '@/Animator/js/utils/itl'
import { useTransform } from '@/Controller/hooks/useTransform'
import { screens } from '../../constants'
import cn from "classnames";
import Canvas from "./Canvas/Canvas";
import CursorWrapper from "@/components/Cursor/CursorWraper";
import { context } from '@/Controller/utils/context'
import Slider from "./components/Slider/Slider";
import { BreakpointsContext } from "@/context/breakpointsContext";
import { useContext } from "react";
import Video from "@/components/UI/Video/Video";
import FewPeople from "./components/FewPeople/FewPeople";
import { memo } from 'react'
import { getProjects } from '@/api/getProjects.js'

const Portfolio = function Portfolio() {
  const { isMaxWidth } = useContext(BreakpointsContext)

  const [sliderProps, setSliderProps] = useState({ index: 0, length: 0 })

  // const socialWrapper = useRef({})
  // const social = useRef({})

  const [following, setFollowing] = useState(false)
  const svgRef = useRef()
  const pathRef = useRef()
  const lineRef = useRef()
  const rect = useRef()
  const follParentRef = useRef()
  const cubeRef = useRef()
  const canvasRef = useRef()
  const armRef = useRef()
  const fewPeopleRef = useRef()
  const { parent, target } = useTransform({ onChange }, { id: screens.PORTFOLIO })
  function onChange({ wheel }) {
    const refs = initRefs()
    if (!refs.mounted) { return }
    const timeline = Timeline(refs)
    const t = itl(timeline, wheel)
    refs.path.style.cssText = `stroke-dashoffset: ${t.svg.offset}`
    refs.rec.style.cssText = `width: ${t.line.width}px; height: ${t.line.height}%;`
    setFollowing(t.followedCont.opacity === 1)
    refs.cube.style.transform = `rotateY(${t.cube.rotateY}deg)`
    // refs.canvas.style.opacity = t.canvas.opacity
    refs.arm.style.transform = `scale(${t.arm.scale})`;
    
    const header = document.querySelector('.header')
    if (header && t.followedCont.opacity === 1 && context.ids[context.active] === screens.PORTFOLIO) {
      header.classList.add('-force-following-color')
    } else {
      header.classList.remove('-force-following-color')
    }
  }
  function initRefs() {
    const par = parent.current
    const tar = target.current
    const rec = rect.current
    const svg = svgRef.current
    const path = pathRef.current
    const line = lineRef.current
    const follParent = follParentRef.current
    const cube = cubeRef.current
    const canvas = canvasRef.current
    const arm = armRef.current
    const fewPeople = fewPeopleRef.current
    const mounted = par && tar && rec && svg && path && line && follParent && cube && canvas && arm && fewPeople
    return { mounted, par, tar, rec, svg, path, line, follParent, cube, canvas, arm, fewPeople }
  }

  const portfolioContainer = useRef()
  const followRefs = useTransform('sticky', {
    id: screens.PORTFOLIO, parent: follParentRef,
    upperElements: [
      fewPeopleRef,
      portfolioContainer,
      target,
      lineRef,
    ],
  })

  const hadlerRoute = (url) => {
    window.open(url, '_blank')
  }

  const [ Projects, setProjects ] = useState([])
  useEffect(() => {
    getProjects().then((data) => {
      setProjects(data)
    })
  }, [])

  const sticky = useTransform('sticky', { id: screens.PORTFOLIO, parent: portfolioContainer, cropMax: true, upperElements: [fewPeopleRef] })

  return (
    <section ref={parent} className="portfolio" id="portfolio">
      <div ref={fewPeopleRef}>
        <FewPeople/>
      </div>
      <div ref={portfolioContainer} className="portfolio__container">
        <div ref={sticky.target} className="portfolio__sticky">
          {
            Projects.length > 0 && (
              <Slider className='portfolio__slider' slides={Projects} onChange={(props) => setSliderProps(props)}>
                <p className='pagination-number'>{ sliderProps.index+1 < 10 ? '0'+(sliderProps.index+1) : sliderProps.index+1 } / { sliderProps.length < 10 ? '0'+sliderProps.length : sliderProps.length }</p>
              </Slider>
            )
          }
        </div>
      </div>
      <div ref={target} className="portfolio__animation">
        <div className="arm">
          <div ref={armRef}>
            <Video src={'/video/portfolio/hand.mp4'} poster={'/video/portfolio/hand.webp'} width={140} height={140}/>
          </div>
        </div>
        <svg ref={svgRef} className="line" width="1728" height="507" viewBox="0 0 1728 507" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path ref={pathRef} d="M1636.5 -0.5C1636.67 46.5 1689.5 135.5 1442.5 74C1223.74 19.5295 1175 151.5 1232.5 117C1257.48 102.013 1263.5 63.7338 1171.5 82C1071.3 101.895 863.504 163 864.504 293V507.5" strokeDasharray="2500" strokeDashoffset="2500" stroke="white" />
        </svg>
      </div>
      <div ref={lineRef} className="portfolio__line"><div ref={rect}></div></div>
      <div ref={follParentRef} className={cn("portfolio__following", following ? '-active' : '')}>
        <div ref={followRefs.target} className="following-sticky">
          <div ref={canvasRef} className="canvas">
            <Canvas parent={followRefs.target} isActive={following} />
          </div>
          <div className="following__cube">
            <div ref={cubeRef} className="cude__wrapper">
              <div className="cube__center">
                  <RandomSpelling disable={isMaxWidth['mobile']}>
                    {$t('pages.following.weFollowed2')}
                  </RandomSpelling>
              </div>
              <div className="cube__right">
                <svg width="667" height="538" viewBox="0 0 667 538" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g onClick={() => hadlerRoute('https://dribbble.com/qclay')} className="cube-link">
                  <rect width="667" height="85" fill="transparent" />
                  <path d="M12.1106 62.8017C5.07264 55.7637 1.55364 47.0882 1.55364 36.7751C1.55364 26.462 5.07264 17.7864 12.1106 10.7484C19.288 3.71041 28.1029 0.191406 38.5554 0.191406C49.0776 0.191406 57.8576 3.71041 64.8956 10.7484C71.9336 17.7864 75.4526 26.462 75.4526 36.7751C75.4526 47.0882 71.9336 55.7637 64.8956 62.8017C57.9273 69.7701 49.1472 73.2542 38.5554 73.2542C27.9636 73.2542 19.1486 69.7701 12.1106 62.8017ZM17.1278 36.6705C17.1278 43.0117 19.1486 48.3076 23.1903 52.5583C27.2319 56.809 32.3536 58.9343 38.5554 58.9343C44.6875 58.9343 49.7744 56.809 53.816 52.5583C57.9273 48.238 59.983 42.942 59.983 36.6705C59.983 30.4687 57.9273 25.2077 53.816 20.8873C49.7744 16.6366 44.6875 14.5113 38.5554 14.5113C32.3536 14.5113 27.2319 16.6366 23.1903 20.8873C19.1486 25.138 17.1278 30.3991 17.1278 36.6705Z" fill="currentColor"/>
                  <path d="M98.5934 71.9999H84.2735V1.44571H97.5482L132.669 46.3913V1.44571H146.988V71.9999H133.818L98.5934 27.0543V71.9999Z" fill="currentColor"/>
                  <path d="M210.91 71.9999H182.061V1.44571H210.91C221.363 1.44571 230.108 4.86018 237.146 11.6891C244.254 18.4484 247.807 26.8104 247.807 36.7751C247.807 46.7398 244.254 55.1017 237.146 61.861C230.178 68.6203 221.432 71.9999 210.91 71.9999ZM210.91 15.243H197.008V58.3072H210.91C216.973 58.3072 222.059 56.2515 226.171 52.1402C230.282 47.8895 232.338 42.7678 232.338 36.7751C232.338 30.643 230.282 25.5212 226.171 21.4099C222.059 17.2986 216.973 15.243 210.91 15.243Z" fill="currentColor"/>
                  <path d="M256.576 1.44571H289.397C296.644 1.44571 302.602 3.67557 307.271 8.13529C312.079 12.595 314.483 18.1697 314.483 24.8592C314.483 29.3887 313.298 33.5 310.929 37.1932C308.629 40.8167 305.494 43.604 301.522 45.5551L317.096 71.9999H300.476L286.47 48.2728H271.523V71.9999H256.576V1.44571ZM289.397 15.243H271.523V34.5801H289.397C292.184 34.5801 294.484 33.6742 296.295 31.8624C298.107 29.981 299.013 27.6466 299.013 24.8592C299.013 22.0719 298.107 19.7724 296.295 17.9606C294.484 16.1489 292.184 15.243 289.397 15.243Z" fill="currentColor"/>
                  <path d="M339.403 71.9999H324.456V1.44571H339.403V71.9999Z" fill="currentColor"/>
                  <path d="M386.247 71.9999H349.873V1.44571H384.261C390.602 1.44571 395.898 3.32715 400.149 7.09004C404.4 10.7136 406.525 15.3127 406.525 20.8873C406.525 26.671 404.33 31.3746 399.94 34.9981C406.281 38.9701 409.452 44.5447 409.452 51.7221C409.452 57.5755 407.222 62.4185 402.762 66.2511C398.302 70.0836 392.797 71.9999 386.247 71.9999ZM386.143 42.4194H364.82V58.3072H386.143C388.303 58.3072 390.115 57.5407 391.578 56.0076C393.111 54.4746 393.878 52.5932 393.878 50.3633C393.878 48.1334 393.111 46.252 391.578 44.719C390.115 43.1859 388.303 42.4194 386.143 42.4194ZM384.052 15.243H364.82V29.3538H384.052C385.864 29.3538 387.397 28.7963 388.651 27.6814C389.975 26.5665 390.742 25.1031 390.951 23.2914C391.02 23.152 391.055 22.8036 391.055 22.2461C391.055 20.2253 390.393 18.5529 389.069 17.2289C387.745 15.905 386.073 15.243 384.052 15.243Z" fill="currentColor"/>
                  <path d="M454.535 71.9999H418.161V1.44571H452.549C458.891 1.44571 464.186 3.32715 468.437 7.09004C472.688 10.7136 474.813 15.3127 474.813 20.8873C474.813 26.671 472.618 31.3746 468.228 34.9981C474.569 38.9701 477.74 44.5447 477.74 51.7221C477.74 57.5755 475.51 62.4185 471.05 66.2511C466.591 70.0836 461.086 71.9999 454.535 71.9999ZM454.431 42.4194H433.108V58.3072H454.431C456.591 58.3072 458.403 57.5407 459.866 56.0076C461.399 54.4746 462.166 52.5932 462.166 50.3633C462.166 48.1334 461.399 46.252 459.866 44.719C458.403 43.1859 456.591 42.4194 454.431 42.4194ZM452.34 15.243H433.108V29.3538H452.34C454.152 29.3538 455.685 28.7963 456.939 27.6814C458.263 26.5665 459.03 25.1031 459.239 23.2914C459.309 23.152 459.343 22.8036 459.343 22.2461C459.343 20.2253 458.681 18.5529 457.357 17.2289C456.034 15.905 454.361 15.243 452.34 15.243Z" fill="currentColor"/>
                  <path d="M522.823 71.9999H486.449V1.44571H520.837C527.179 1.44571 532.475 3.32715 536.725 7.09004C540.976 10.7136 543.101 15.3127 543.101 20.8873C543.101 26.671 540.906 31.3746 536.516 34.9981C542.857 38.9701 546.028 44.5447 546.028 51.7221C546.028 57.5755 543.798 62.4185 539.338 66.2511C534.879 70.0836 529.374 71.9999 522.823 71.9999ZM522.719 42.4194H501.396V58.3072H522.719C524.879 58.3072 526.691 57.5407 528.154 56.0076C529.687 54.4746 530.454 52.5932 530.454 50.3633C530.454 48.1334 529.687 46.252 528.154 44.719C526.691 43.1859 524.879 42.4194 522.719 42.4194ZM520.628 15.243H501.396V29.3538H520.628C522.44 29.3538 523.973 28.7963 525.228 27.6814C526.552 26.5665 527.318 25.1031 527.527 23.2914C527.597 23.152 527.632 22.8036 527.632 22.2461C527.632 20.2253 526.97 18.5529 525.646 17.2289C524.322 15.905 522.649 15.243 520.628 15.243Z" fill="currentColor"/>
                  <path d="M554.737 1.44571H569.684V58.3072H604.386V71.9999H554.737V1.44571Z" fill="currentColor"/>
                  <path d="M611.899 1.44571H664.266V15.243H626.846V29.5629H658.622V43.2556H626.846V58.3072H664.788V71.9999H611.899V1.44571Z" fill="currentColor"/>
                </g>
                <g onClick={() => hadlerRoute('https://www.instagram.com/qclaydesign/')} className="cube-link">
                  <rect y="85" width="667" height="85" fill="transparent" />
                  <path d="M10.2364 148.147C4.13317 142.044 1.08157 134.521 1.08157 125.577C1.08157 116.634 4.13317 109.111 10.2364 103.008C16.4604 96.9044 24.1045 93.8528 33.1687 93.8528C42.2933 93.8528 49.9072 96.9044 56.0104 103.008C62.1136 109.111 65.1652 116.634 65.1652 125.577C65.1652 134.521 62.1136 142.044 56.0104 148.147C49.9676 154.19 42.3537 157.211 33.1687 157.211C23.9837 157.211 16.3396 154.19 10.2364 148.147ZM14.5872 125.487C14.5872 130.986 16.3396 135.578 19.8444 139.264C23.3492 142.95 27.7906 144.793 33.1687 144.793C38.4863 144.793 42.8976 142.95 46.4024 139.264C49.9676 135.518 51.7502 130.925 51.7502 125.487C51.7502 120.109 49.9676 115.546 46.4024 111.8C42.8976 108.114 38.4863 106.271 33.1687 106.271C27.7906 106.271 23.3492 108.114 19.8444 111.8C16.3396 115.486 14.5872 120.048 14.5872 125.487Z" fill="currentColor"/>
                  <path d="M85.2324 156.124H72.8145V94.9405H84.326L114.782 133.916V94.9405H127.199V156.124H115.779L85.2324 117.148V156.124Z" fill="currentColor"/>
                  <path d="M170.576 156.124H157.614V94.9405H170.576V156.124Z" fill="currentColor"/>
                  <path d="M192.073 156.124H179.655V94.9405H191.166L221.622 133.916V94.9405H234.04V156.124H222.619L192.073 117.148V156.124Z" fill="currentColor"/>
                  <path d="M285.995 108.084L273.215 111.165C272.792 109.655 271.734 108.386 270.042 107.358C268.35 106.271 266.416 105.727 264.241 105.727C261.945 105.727 260.011 106.271 258.44 107.358C256.869 108.446 256.083 109.836 256.083 111.528C256.083 113.643 257.957 115.425 261.703 116.876C262.791 117.299 265.782 118.235 270.677 119.686C274.484 120.773 277.596 122.042 280.013 123.493C285.33 126.212 288.14 130.986 288.442 137.814C288.442 143.736 286.327 148.449 282.097 151.954C277.868 155.459 272.399 157.211 265.691 157.211C259.588 157.211 254.301 155.761 249.829 152.86C245.478 149.96 242.668 146.244 241.399 141.712L254.361 138.539C254.845 140.473 256.114 142.074 258.168 143.343C260.283 144.612 262.791 145.247 265.691 145.247C268.471 145.247 270.797 144.673 272.671 143.524C274.604 142.316 275.571 140.805 275.571 138.992C275.934 137.482 274.786 135.941 272.127 134.37C271.643 134.128 270.828 133.765 269.68 133.282C268.411 132.798 267.625 132.527 267.323 132.466L264.785 131.65L259.437 130.019L254.633 128.115C252.76 127.39 251.189 126.544 249.92 125.577C244.965 121.891 242.699 117.51 243.122 112.434C243.122 106.935 245.055 102.464 248.923 99.0194C252.85 95.575 257.896 93.8528 264.06 93.8528C269.559 93.8528 274.302 95.2124 278.291 97.9317C282.339 100.53 284.907 103.914 285.995 108.084Z" fill="currentColor"/>
                  <path d="M310.613 106.905H291.125V94.9405H342.972V106.905H323.484V156.124H310.613V106.905Z" fill="currentColor"/>
                  <path d="M351.605 156.124H338.1L364.023 94.9405H374.628L400.552 156.124H387.046L382.605 145.337H356.047L351.605 156.124ZM377.801 133.644L369.371 113.341L360.942 133.644H377.801Z" fill="currentColor"/>
                  <path d="M401.179 125.577C401.179 116.513 404.291 108.96 410.515 102.917C416.739 96.8742 424.474 93.8528 433.72 93.8528C440.065 93.8528 445.835 95.3937 451.032 98.4755C456.289 101.618 460.217 105.848 462.816 111.165L450.307 115.788C448.736 112.827 446.47 110.501 443.509 108.809C440.548 107.117 437.285 106.271 433.72 106.271C428.281 106.271 423.749 108.114 420.123 111.8C416.498 115.486 414.685 120.048 414.685 125.487C414.685 130.925 416.407 135.518 419.851 139.264C423.356 142.95 427.707 144.793 432.904 144.793C436.832 144.793 440.367 143.736 443.509 141.621C446.651 139.446 448.796 136.575 449.944 133.01H431.363V121.952H464.628C464.628 122.254 464.659 122.828 464.719 123.674C464.84 124.459 464.9 124.973 464.9 125.215C464.78 134.46 461.728 142.074 455.746 148.056C449.642 154.16 442.089 157.211 433.085 157.211C424.021 157.211 416.437 154.19 410.334 148.147C404.231 142.044 401.179 134.521 401.179 125.577Z" fill="currentColor"/>
                  <path d="M472.558 94.9405H501.02C507.304 94.9405 512.471 96.8742 516.519 100.742C520.689 104.609 522.774 109.443 522.774 115.244C522.774 119.172 521.746 122.737 519.692 125.94C517.698 129.082 514.978 131.499 511.534 133.191L525.04 156.124H510.628L498.482 135.548H485.52V156.124H472.558V94.9405ZM501.02 106.905H485.52V123.674H501.02C503.437 123.674 505.431 122.888 507.002 121.317C508.573 119.686 509.359 117.661 509.359 115.244C509.359 112.827 508.573 110.833 507.002 109.262C505.431 107.691 503.437 106.905 501.02 106.905Z" fill="currentColor"/>
                  <path d="M541.121 156.124H527.615L553.539 94.9405H564.144L590.067 156.124H576.562L572.12 145.337H545.562L541.121 156.124ZM567.316 133.644L558.887 113.341L550.457 133.644H567.316Z" fill="currentColor"/>
                  <path d="M608.112 156.124H595.332V94.9405H608.112L629.232 132.285L650.442 94.9405H663.222V156.124H650.442V118.961L633.492 149.416H624.971L608.112 119.142V156.124Z" fill="currentColor"/>
                </g>
                <g onClick={() => hadlerRoute('https://www.tiktok.com/@qclay.design')} className="cube-link">
                  <rect y="170" width="667" height="120" fill="transparent" />
                  <path d="M15.3537 257.839C6.69815 249.183 2.37038 238.514 2.37038 225.83C2.37038 213.147 6.69815 202.477 15.3537 193.822C24.1806 185.166 35.0215 180.839 47.8763 180.839C60.8167 180.839 71.6147 185.166 80.2703 193.822C88.9258 202.477 93.2536 213.147 93.2536 225.83C93.2536 238.514 88.9258 249.183 80.2703 257.839C71.7004 266.408 60.9024 270.693 47.8763 270.693C34.8501 270.693 24.0092 266.408 15.3537 257.839ZM21.524 225.702C21.524 233.5 24.0092 240.013 28.9798 245.241C33.9503 250.469 40.2491 253.082 47.8763 253.082C55.4177 253.082 61.6737 250.469 66.6442 245.241C71.7004 239.928 74.2285 233.415 74.2285 225.702C74.2285 218.075 71.7004 211.604 66.6442 206.291C61.6737 201.063 55.4177 198.45 47.8763 198.45C40.2491 198.45 33.9503 201.063 28.9798 206.291C24.0092 211.519 21.524 217.989 21.524 225.702Z" fill="currentColor"/>
                  <path d="M121.713 269.151H104.102V182.381H120.427L163.619 237.657V182.381H181.23V269.151H165.033L121.713 213.875V269.151Z" fill="currentColor"/>
                  <path d="M248.274 199.349H220.636V182.381H294.166V199.349H266.528V269.151H248.274V199.349Z" fill="currentColor"/>
                  <path d="M321.833 269.151H303.451V182.381H321.833V269.151Z" fill="currentColor"/>
                  <path d="M334.709 269.151V182.381H353.092V219.017L383.429 182.381H406.696L371.603 224.802L409.138 269.151H385.871L353.092 230.458V269.151H334.709Z" fill="currentColor"/>
                  <path d="M440.468 199.349H412.83V182.381H486.359V199.349H458.722V269.151H440.468V199.349Z" fill="currentColor"/>
                  <path d="M503.182 257.839C494.527 249.183 490.199 238.514 490.199 225.83C490.199 213.147 494.527 202.477 503.182 193.822C512.009 185.166 522.85 180.839 535.705 180.839C548.645 180.839 559.443 185.166 568.099 193.822C576.754 202.477 581.082 213.147 581.082 225.83C581.082 238.514 576.754 249.183 568.099 257.839C559.529 266.408 548.731 270.693 535.705 270.693C522.678 270.693 511.838 266.408 503.182 257.839ZM509.352 225.702C509.352 233.5 511.838 240.013 516.808 245.241C521.779 250.469 528.077 253.082 535.705 253.082C543.246 253.082 549.502 250.469 554.473 245.241C559.529 239.928 562.057 233.415 562.057 225.702C562.057 218.075 559.529 211.604 554.473 206.291C549.502 201.063 543.246 198.45 535.705 198.45C528.077 198.45 521.779 201.063 516.808 206.291C511.838 211.519 509.352 217.989 509.352 225.702Z" fill="currentColor"/>
                  <path d="M591.93 269.151V182.381H610.312V219.017L640.65 182.381H663.917L628.823 224.802L666.359 269.151H643.092L610.312 230.458V269.151H591.93Z" fill="currentColor"/>
                </g>
                <g onClick={() => hadlerRoute('https://www.behance.net/qclay')} className="cube-link">
                  <rect y="290" width="667" height="95" fill="transparent" />
                  <path d="M12.1919 359.12C5.11334 352.041 1.57407 343.316 1.57407 332.943C1.57407 322.571 5.11334 313.845 12.1919 306.767C19.4106 299.688 28.2763 296.149 38.789 296.149C49.3717 296.149 58.2024 299.688 65.2809 306.767C72.3595 313.845 75.8988 322.571 75.8988 332.943C75.8988 343.316 72.3595 352.041 65.2809 359.12C58.2725 366.128 49.4418 369.632 38.789 369.632C28.1361 369.632 19.2704 366.128 12.1919 359.12ZM17.238 332.838C17.238 339.216 19.2704 344.542 23.3353 348.817C27.4002 353.092 32.5514 355.23 38.789 355.23C44.9564 355.23 50.0726 353.092 54.1375 348.817C58.2725 344.472 60.34 339.146 60.34 332.838C60.34 326.6 58.2725 321.309 54.1375 316.964C50.0726 312.689 44.9564 310.551 38.789 310.551C32.5514 310.551 27.4002 312.689 23.3353 316.964C19.2704 321.239 17.238 326.53 17.238 332.838Z" fill="currentColor"/>
                  <path d="M99.1728 368.371H84.7705V297.41H98.1216L133.444 342.615V297.41H147.847V368.371H134.601L99.1728 323.166V368.371Z" fill="currentColor"/>
                  <path d="M219.706 368.371H183.122V297.41H217.708C224.086 297.41 229.412 299.303 233.688 303.087C237.963 306.731 240.1 311.357 240.1 316.964C240.1 322.781 237.893 327.512 233.477 331.156C239.855 335.151 243.044 340.758 243.044 347.976C243.044 353.863 240.801 358.734 236.316 362.589C231.83 366.443 226.294 368.371 219.706 368.371ZM219.601 338.62H198.155V354.599H219.601C221.773 354.599 223.595 353.828 225.067 352.286C226.609 350.745 227.38 348.852 227.38 346.61C227.38 344.367 226.609 342.475 225.067 340.933C223.595 339.391 221.773 338.62 219.601 338.62ZM217.498 311.287H198.155V325.479H217.498C219.32 325.479 220.862 324.918 222.124 323.797C223.455 322.676 224.226 321.204 224.436 319.382C224.506 319.242 224.542 318.891 224.542 318.33C224.542 316.298 223.876 314.616 222.544 313.284C221.212 311.953 219.53 311.287 217.498 311.287Z" fill="currentColor"/>
                  <path d="M251.803 297.41H304.472V311.287H266.836V325.689H298.795V339.461H266.836V354.599H304.997V368.371H251.803V297.41Z" fill="currentColor"/>
                  <path d="M328.331 368.371H313.298V297.41H328.331V325.584H360.815V297.41H375.743V368.371H360.815V339.461H328.331V368.371Z" fill="currentColor"/>
                  <path d="M397.54 368.371H381.876L411.942 297.41H424.242L454.309 368.371H438.645L433.493 355.861H402.691L397.54 368.371ZM427.922 342.299L418.145 318.751L408.368 342.299H427.922Z" fill="currentColor"/>
                  <path d="M474.816 368.371H460.414V297.41H473.765L509.088 342.615V297.41H523.49V368.371H510.244L474.816 323.166V368.371Z" fill="currentColor"/>
                  <path d="M569.556 296.149C576.915 296.149 583.573 297.971 589.53 301.615C595.488 305.19 599.973 310.06 602.987 316.228L588.479 321.589C586.587 318.155 583.994 315.457 580.7 313.495C577.406 311.532 573.691 310.551 569.556 310.551C563.319 310.551 558.168 312.689 554.103 316.964C550.038 321.239 548.005 326.53 548.005 332.838C548.005 339.216 550.038 344.542 554.103 348.817C558.168 353.092 563.319 355.23 569.556 355.23C573.691 355.23 577.406 354.249 580.7 352.286C583.994 350.324 586.587 347.626 588.479 344.192L602.987 349.553C599.973 355.721 595.488 360.626 589.53 364.271C583.573 367.845 576.915 369.632 569.556 369.632C558.903 369.632 550.038 366.128 542.959 359.12C535.881 352.041 532.341 343.316 532.341 332.943C532.341 322.571 535.881 313.845 542.959 306.767C550.178 299.688 559.044 296.149 569.556 296.149Z" fill="currentColor"/>
                  <path d="M611.226 297.41H663.894V311.287H626.259V325.689H658.218V339.461H626.259V354.599H664.42V368.371H611.226V297.41Z" fill="currentColor"/>
                </g>
                <g onClick={() => hadlerRoute('https://www.pinterest.com/qclaydesign/')} className="cube-link">
                  <rect y="385" width="667" height="85" fill="transparent" />
                  <path d="M11.073 450.478C4.55254 443.958 1.29229 435.92 1.29229 426.365C1.29229 416.81 4.55254 408.773 11.073 402.252C17.7227 395.732 25.8894 392.471 35.5733 392.471C45.3218 392.471 53.4563 395.732 59.9768 402.252C66.4973 408.773 69.7575 416.81 69.7575 426.365C69.7575 435.92 66.4973 443.958 59.9768 450.478C53.5208 456.934 45.3864 460.162 35.5733 460.162C25.7603 460.162 17.5935 456.934 11.073 450.478ZM15.7213 426.268C15.7213 432.143 17.5935 437.05 21.338 440.988C25.0824 444.926 29.8275 446.895 35.5733 446.895C41.2546 446.895 45.9674 444.926 49.7118 440.988C53.5209 436.985 55.4254 432.079 55.4254 426.268C55.4254 420.522 53.5209 415.648 49.7118 411.646C45.9674 407.707 41.2546 405.738 35.5733 405.738C29.8275 405.738 25.0824 407.707 21.338 411.646C17.5935 415.584 15.7213 420.458 15.7213 426.268Z" fill="currentColor"/>
                  <path d="M91.1968 459H77.9298V393.634H90.2284L122.766 435.274V393.634H136.033V459H123.832L91.1968 417.359V459Z" fill="currentColor"/>
                  <path d="M182.375 437.017V459H168.527V393.634H198.063C204.713 393.634 210.233 395.699 214.623 399.831C219.077 403.963 221.305 409.128 221.305 415.325C221.305 421.459 219.077 426.623 214.623 430.82C210.233 434.952 204.713 437.017 198.063 437.017H182.375ZM198.063 406.416H182.375V424.332H198.063C200.581 424.332 202.679 423.492 204.358 421.814C206.101 420.071 206.972 417.908 206.972 415.325C206.972 412.743 206.101 410.613 204.358 408.934C202.679 407.256 200.581 406.416 198.063 406.416Z" fill="currentColor"/>
                  <path d="M242.9 459H229.052V393.634H242.9V459Z" fill="currentColor"/>
                  <path d="M265.867 459H252.6V393.634H264.898L297.436 435.274V393.634H310.703V459H298.501L265.867 417.359V459Z" fill="currentColor"/>
                  <path d="M338.418 406.416H317.598V393.634H372.99V406.416H352.169V459H338.418V406.416Z" fill="currentColor"/>
                  <path d="M379.985 393.634H428.501V406.416H393.833V419.683H423.272V432.369H393.833V446.314H428.985V459H379.985V393.634Z" fill="currentColor"/>
                  <path d="M436.632 393.634H467.039C473.753 393.634 479.273 395.699 483.599 399.831C488.053 403.963 490.281 409.128 490.281 415.325C490.281 419.522 489.183 423.331 486.988 426.753C484.858 430.11 481.952 432.692 478.273 434.5L492.702 459H477.304L464.328 437.017H450.48V459H436.632V393.634ZM467.039 406.416H450.48V424.332H467.039C469.622 424.332 471.752 423.492 473.431 421.814C475.109 420.071 475.948 417.908 475.948 415.325C475.948 412.743 475.109 410.613 473.431 408.934C471.752 407.256 469.622 406.416 467.039 406.416Z" fill="currentColor"/>
                  <path d="M499.52 393.634H548.037V406.416H513.368V419.683H542.807V432.369H513.368V446.314H548.521V459H499.52V393.634Z" fill="currentColor"/>
                  <path d="M601.972 407.675L588.318 410.968C587.866 409.354 586.736 407.998 584.929 406.9C583.121 405.738 581.055 405.157 578.731 405.157C576.278 405.157 574.212 405.738 572.533 406.9C570.855 408.063 570.016 409.547 570.016 411.355C570.016 413.615 572.017 415.519 576.02 417.069C577.182 417.52 580.377 418.521 585.607 420.071C589.674 421.233 592.999 422.588 595.581 424.138C601.262 427.043 604.264 432.143 604.587 439.438C604.587 445.765 602.328 450.801 597.808 454.545C593.289 458.29 587.447 460.162 580.28 460.162C573.76 460.162 568.111 458.613 563.334 455.514C558.685 452.415 555.683 448.444 554.328 443.603L568.176 440.213C568.692 442.279 570.048 443.99 572.243 445.346C574.502 446.701 577.182 447.379 580.28 447.379C583.25 447.379 585.736 446.766 587.737 445.539C589.803 444.248 590.836 442.634 590.836 440.697C591.223 439.083 589.997 437.437 587.156 435.759C586.64 435.5 585.768 435.113 584.541 434.596C583.186 434.08 582.346 433.789 582.024 433.725L579.312 432.853L573.599 431.11L568.466 429.077C566.465 428.302 564.786 427.398 563.43 426.365C558.137 422.427 555.716 417.746 556.168 412.323C556.168 406.449 558.233 401.671 562.365 397.991C566.562 394.311 571.952 392.471 578.537 392.471C584.412 392.471 589.48 393.924 593.741 396.829C598.067 399.605 600.81 403.221 601.972 407.675Z" fill="currentColor"/>
                  <path d="M628.273 406.416H607.453V393.634H662.845V406.416H642.024V459H628.273V406.416Z" fill="currentColor"/>
                </g>
                <g onClick={() => hadlerRoute('https://www.awwwards.com/QClay/')} className="cube-link">
                  <rect y="470" width="667" height="90" fill="transparent" />
                  <path d="M9.63912 528.422C3.83381 522.617 0.931152 515.461 0.931152 506.954C0.931152 498.447 3.83381 491.291 9.63912 485.486C15.5594 479.68 22.8304 476.778 31.4521 476.778C40.1314 476.778 47.3736 479.68 53.1789 485.486C58.9842 491.291 61.8869 498.447 61.8869 506.954C61.8869 515.461 58.9842 522.617 53.1789 528.422C47.4311 534.17 40.1888 537.044 31.4521 537.044C22.7154 537.044 15.4444 534.17 9.63912 528.422ZM13.7776 506.868C13.7776 512.098 15.4444 516.466 18.7782 519.973C22.1119 523.479 26.3366 525.232 31.4521 525.232C36.5102 525.232 40.7061 523.479 44.0399 519.973C47.4311 516.409 49.1267 512.041 49.1267 506.868C49.1267 501.752 47.4311 497.412 44.0399 493.849C40.7061 490.343 36.5102 488.589 31.4521 488.589C26.3366 488.589 22.1119 490.343 18.7782 493.849C15.4444 497.355 13.7776 501.695 13.7776 506.868Z" fill="currentColor"/>
                  <path d="M80.9746 536.009H69.1628V477.812H80.1124L109.082 514.886V477.812H120.893V536.009H110.03L80.9746 498.936V536.009Z" fill="currentColor"/>
                  <path d="M159.049 536.009H146.202L170.86 477.812H180.948L205.606 536.009H192.76L188.535 525.749H163.273L159.049 536.009ZM183.965 514.627L175.947 495.314L167.929 514.627H183.965Z" fill="currentColor"/>
                  <path d="M228.063 536.009H217.976L198.404 477.812H210.992L222.89 516.265L234.443 477.812H244.53L256.17 516.179L267.982 477.812H280.569L260.998 536.009H250.911L239.53 499.194L228.063 536.009Z" fill="currentColor"/>
                  <path d="M311.502 536.009H301.414L281.843 477.812H294.431L306.329 516.265L317.882 477.812H327.969L339.609 516.179L351.421 477.812H364.008L344.437 536.009H334.35L322.969 499.194L311.502 536.009Z" fill="currentColor"/>
                  <path d="M394.941 536.009H384.853L365.282 477.812H377.87L389.768 516.265L401.321 477.812H411.408L423.048 516.179L434.86 477.812H447.447L427.876 536.009H417.788L406.408 499.194L394.941 536.009Z" fill="currentColor"/>
                  <path d="M452.979 536.009H440.133L464.791 477.812H474.879L499.537 536.009H486.69L482.466 525.749H457.204L452.979 536.009ZM477.896 514.627L469.878 495.314L461.86 514.627H477.896Z" fill="currentColor"/>
                  <path d="M504.544 477.812H531.616C537.594 477.812 542.509 479.652 546.36 483.33C550.326 487.009 552.309 491.607 552.309 497.125C552.309 500.861 551.331 504.252 549.377 507.299C547.48 510.288 544.894 512.587 541.618 514.196L554.464 536.009H540.755L529.202 516.438H516.873V536.009H504.544V477.812ZM531.616 489.193H516.873V505.143H531.616C533.916 505.143 535.812 504.396 537.307 502.902C538.801 501.35 539.548 499.424 539.548 497.125C539.548 494.826 538.801 492.929 537.307 491.435C535.812 489.94 533.916 489.193 531.616 489.193Z" fill="currentColor"/>
                  <path d="M584.331 536.009H560.535V477.812H584.331C592.953 477.812 600.166 480.629 605.972 486.262C611.834 491.837 614.766 498.734 614.766 506.954C614.766 515.173 611.834 522.071 605.972 527.646C600.224 533.221 593.01 536.009 584.331 536.009ZM584.331 489.193H572.864V524.715H584.331C589.332 524.715 593.527 523.019 596.919 519.628C600.31 516.122 602.006 511.897 602.006 506.954C602.006 501.896 600.31 497.671 596.919 494.28C593.527 490.889 589.332 489.193 584.331 489.193Z" fill="currentColor"/>
                  <path d="M661.601 490.314L649.444 493.245C649.042 491.808 648.036 490.601 646.426 489.624C644.817 488.589 642.978 488.072 640.908 488.072C638.724 488.072 636.885 488.589 635.391 489.624C633.896 490.659 633.149 491.981 633.149 493.59C633.149 495.602 634.931 497.297 638.494 498.677C639.529 499.079 642.374 499.97 647.03 501.35C650.651 502.384 653.611 503.591 655.91 504.971C660.968 507.557 663.641 512.098 663.929 518.593C663.929 524.226 661.917 528.709 657.893 532.043C653.87 535.377 648.668 537.044 642.288 537.044C636.483 537.044 631.453 535.664 627.2 532.905C623.061 530.146 620.389 526.611 619.182 522.3L631.511 519.283C631.971 521.122 633.178 522.645 635.132 523.852C637.144 525.059 639.529 525.663 642.288 525.663C644.932 525.663 647.145 525.117 648.927 524.025C650.766 522.875 651.686 521.438 651.686 519.714C652.03 518.277 650.938 516.811 648.409 515.317C647.95 515.087 647.174 514.742 646.081 514.282C644.874 513.822 644.127 513.564 643.84 513.506L641.426 512.73L636.339 511.178L631.769 509.368C629.988 508.678 628.493 507.873 627.286 506.954C622.573 503.448 620.417 499.28 620.82 494.452C620.82 489.222 622.659 484.968 626.338 481.692C630.074 478.416 634.873 476.778 640.736 476.778C645.967 476.778 650.479 478.071 654.272 480.657C658.123 483.129 660.566 486.348 661.601 490.314Z" fill="currentColor"/>
                </g>
                </svg>
              </div>
            </div>
          </div>
          <CursorWrapper className="cursor-followed-wrapper" t={0.04}>
            <div className='cursor-followed'>
              <div></div>
              <div></div>
            </div>
          </CursorWrapper>
        </div>
      </div>
    </section>
  )
}

export default Portfolio
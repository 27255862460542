import './Create.scss'
import './Anim.scss'
import { memo } from 'react'

const Create = memo(function Create() {
  return (
    <section className="create">
      <div className='create__container'>
        <div className="text">
          <div className='text-lighten'>
            <div className="row row_one"></div>
            <div className="row row_two"></div>
            <div className="row row_three"></div>
            <div className="row row_four"></div>
            <div className="row row_five"></div>
            <div className="row row_six"></div>
            <div className="row row_seven"></div>
          </div>
        </div>
      </div>
    </section>
  )
})

export default Create